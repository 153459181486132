import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/services/alert/snack-bar.service';
import { AuthService } from 'src/services/auth-user/auth.service';
import { EndPointService } from 'src/services/end-point.service';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../operations/user/forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  progress_loader = false;
  hide = true;
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  constructor(
    private endpoint:EndPointService,
    private navigate: Router,
    public _snack_bar: SnackBarService,
    public authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  get f(){
    return this.loginForm.controls;
  }

  getLogIn(){
      if(this.progress_loader === true){
        return;
      }
      this.progress_loader = true;
      this.endpoint.postData('users/login', this.loginForm.value).subscribe(resp => {
        if (resp.status && resp.message == "success") {
            this.progress_loader = false;
            localStorage.setItem('trip-zolo-crm', JSON.stringify(resp.data));
            this.authService.setLogin(resp.data);
            this._snack_bar.info('Your last login time: '+resp.data.last_login);
            // this.navigate.navigateByUrl('operation/dashboard');
            if(this.authService.isCoordinator() || this.authService.isLogistic()){
            // this.navigate.navigateByUrl('coordinator');
              window.location.href = "coordinator";
              return;
            }
            else if(this.authService.isAgent()){
              // this.navigate.navigateByUrl('agency');
                window.location.href = "agency";
                return;
              }
            else if(this.authService.isDriver()){
              // this.navigate.navigateByUrl('drivers/tour-allocation');
              window.location.href = "drivers/tour-allocation";
              return;
            }else if(this.authService.isGroundManager()){
              // this.navigate.navigateByUrl('ground-manager/confirmation-list');
              window.location.href = "ground-manager/confirmation-list";
              return;
            }else if(this.authService.isGroundOperation()){
              // this.navigate.navigateByUrl('ground-manager/confirmation-list');
              window.location.href = "ground-manager/confirmation-list";
              return;
            }else{
              // this.navigate.navigateByUrl('operation/dashboard');
              window.location.href = "operation/dashboard";
              return;
            }
        }
        this.progress_loader = false;
        this._snack_bar.error(resp.message);
      }, err => {
        this._snack_bar.error('Oops :)\nError while login.');
      });
    }

    forgotPassword(){
      this.dialog.open(ForgotPasswordComponent);
    }
}
